import React from 'react';

import LayoutEs from '../components/LayoutEs';
import Header from '../components/Header';
import { Link } from 'gatsby';

const IndexPage = () => (
  <LayoutEs>
    <Header />
    <header className="masthead error d-flex">
      <div className="container text-center my-auto">
        <h1 className="mb-1">Ups, página no encontrada</h1>
        <h3 className="mb-5">
          <em>Lo sentimos pero la página que buscas no está disponible.</em>
        </h3>

        <Link className="text-white text-uppercase my-auto" to="/">
          Volver a inicio
        </Link>
      </div>
      <div className="overlay"></div>
    </header>
  </LayoutEs>
);

export default IndexPage;
