import React, { Component } from 'react';
import config from '../../config';
import { Link } from 'gatsby';
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
    };
  }
  toggleMenu = value => {
    this.setState({ openMenu: value });
  };

  render() {
    const { openMenu } = this.state;
    return (
      <>
      <Link to="/" title="Beköme Digital Agencia Ecommerce"><span className="logo"></span><span className='sublogo'></span></Link>
        <a
          onClick={_ => this.toggleMenu(!openMenu)}
          className={`menu-toggle rounded ${openMenu ? 'active' : ''}`}
          href="#"
          title="menu"
        >
          {openMenu ? (
            <i className="fas fa-times fa-2x" />
          ) : (
            <i className="fas fa-bars fa-2x" />
          )}
        </a>
        <nav id="sidebar-wrapper" className={` ${openMenu ? 'active' : ''}`}>
          <ul className="sidebar-nav">
            <li className="sidebar-brand">
              <span>{config.companyName}</span>
            </li>
            <li className="sidebar-nav-item">
              <Link
                onClick={_ => this.toggleMenu(!openMenu)}
                to="/"
              >Inicio
              </Link>
            </li>
            <li className="sidebar-nav-item">
              <Link
                onClick={_ => this.toggleMenu(!openMenu)}
                to="/servicios/"
              >Servicios
              </Link>
            </li>
            <li className="sidebar-nav-item">
              <Link
                onClick={_ => this.toggleMenu(!openMenu)}
                to="/proyectos-magento-ecommerce/"
              >Magento 2
              </Link>
            </li>
            <li className="sidebar-nav-item">
              <Link
                onClick={_ => this.toggleMenu(!openMenu)}
                to="/agencia-partner-bigcommerce/"
              >BigCommerce
              </Link>
            </li>
            <li className="sidebar-nav-item">
              <Link
                onClick={_ => this.toggleMenu(!openMenu)}
                to="/nosotros/"
              >Sobre Nosotros
              </Link>
            </li>
            <li className="sidebar-nav-item">
              <Link
                onClick={_ => this.toggleMenu(!openMenu)}
                to="/casos-de-exito/"
              >Casos de éxito
              </Link>
            </li>
            <li className="sidebar-nav-item">
              <Link
                onClick={_ => this.toggleMenu(!openMenu)}
                to="/contacto/"
              >Contacto
              </Link>
            </li>
            <li className="sidebar-nav-item">
              <Link
                onClick={_ => this.toggleMenu(!openMenu)}
                to="/blog/"
              >Blog
              </Link>
            </li>
          </ul>
        </nav>
      </>
    );
  }
}
