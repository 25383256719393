module.exports = {
  siteTitle_es_ES: 'Expertos E-commerce Barcelona', // <title>
  siteTitle_en_US: 'Magento E-commerce specialists', // <title>
  siteTitle_Blog: 'Blog Bekome Digital',
  companyName: 'Beköme Digital',
  manifestName: 'Bekome Digital',
  manifestShortName: 'Bekome', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#ff5c5c',
  manifestThemeColor: '#ff5c5c',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/bekome-favicon.png',
  pathPrefix: `/bekome/`, // This path is subpath of your hosting https://domain/portfolio
  heading_es_ES: 'Especialistas en Ecommerce',
  subHeading_es_ES: 'Desarrollo de proyectos para empresas',
  heading_en_US: 'E-commerce specialists',
  subHeading_en_US: 'Professional services specializing in digital commerce',
  description: 'Somos una agencia experta en desarrollo ecommerce bajo las plataformas Magento 2 y BigCommerce en Barcelona. Desarrolladores certificados en Magento 2',
  description_Blog: 'Blog ecommerce Bekome Digital. Magento y BigCommerce.',
  author_web: 'Bekome Digital',
  author: 'Bekome Digital blogger',
  canonicalUrl: 'https://www.bekome.digital',
  robots: 'INDEX,FOLLOW',
  copy_footer: '2023 Bekome Digital · Expertos Ecommerce · Barcelona',

  // social
  socialLinks: [
    {
      icon: 'icon-social-linkedin',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/company/bekome-digital/',
    },
    // {
    //   icon: 'icon-social-twitter',
    //   name: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
    // {
    //   icon: 'icon-social-instagram',
    //   name: 'Instagram',
    //   url: 'https://instagram.com/',
    // },
  ],
  email: 'hola@bekome.digital',
  phone: '+34 694 454 907',
  address: 'C/ Font Vella, 78, 08221 Terrassa - Barcelona',
  street: 'C/ Font Vella, 78',
  postcode: '08221 Terrassa',
  region: 'Barcelona'
};
